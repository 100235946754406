body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  color: rgb(13, 22, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.headerSection {
  padding-top: 20px;
  border: 3px solid rgb(104, 159, 42);
  background-color: rgb(104, 159, 42);
  width: 100%;
}
.headerShadow{
  box-shadow: 0px 3px 18px rgb(104, 159, 42);
}
.header {
  margin: auto;
  width: 100%;
  max-width: 1200px;
}
.fixedHeaderMenu{
  z-index: 15;
  position: fixed;
  top: 0;
  left:0;
}
.showFixed{
  animation: fixedShow 500ms linear 0s;
}
.hideFixed{
  animation: fixedHide 500ms linear 0s;
}
@keyframes fixedHide{
  0% {
      top: 0px;
  }
  100% {
      top: -100px;
  }
}
@keyframes fixedShow
{
0% {
  top: -100px;
}
100% {
  top: 0px;
}
}
.brand {
  width: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
}
.brand img {
  width: 100px;
}
.brandName {
  padding: 5px;
}
.brandName p {
  display: block;
  padding: 0px;
  margin: 0;
  font-size: 1.5em;
}
.brandName p:first-child {
  font-weight: bolder;
}
.logo {
  animation: logoRotate 2000ms linear;
}
@keyframes logoRotate {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0);
  }
}
a {
  text-decoration: none;
  color: black;
}
.hBtn {
  background-color: transparent;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1em;
  text-transform: none;
  color: rgb(221, 250, 189);
  margin: 0 20px;
  transition: all 200ms linear;
}
.hBtn:hover {
  transform: scale(1.05);
}

.main {
  margin: auto;
  width: 100%;
  max-width: 1200px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.main > * {
  width: 100%;
}

.aboutText {
  width: 70%;
  padding: 0 20px;
}
.sideIcon {
  width: 30%;
}
.sideIcon img {
  width: 100%;
  border-radius: 5px;
}
.advantages {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
}
.advantages>*{
  display:flex;
  align-items: center;
  height: 50px;
}
.advantages>*:nth-child(1){
  
  animation: toRight 1s linear;
}
.advantages>*:nth-child(2){
  
  animation: toRight 1.4s linear;
}
.advantages>*:nth-child(3){
  
  animation: toRight 1.8s linear;
}

.advantages div{
  margin: 20px 10px;
}
@keyframes toRight {
  0% {
    transform: translate(-80px);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.serviceSection {
  background-color: rgb(172, 197, 145);
}
.servicesArea {
  margin: auto;
  width: 100%;
  max-width: 1200px;
  text-align: center;
  padding: 45px 0;
}
.serviceItems {
  position: relative;
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.services {
  padding: 15px;
  box-sizing: border-box;
  width: 25%;
}
.serviceExpand {
  position: fixed;
  top: 100px;
  padding: 5%;
  width: 500px;
  border-radius: 10px;
  background-color: rgba(158, 196, 116, 0.952);
  box-shadow: 0 0 16px rgba(107, 182, 22, 0.938);
  z-index: 5;
}
.serviceExpand img {
  max-width: 80%;
}
.serviceExpand:hover {
  box-shadow: 0 0 18px rgba(75, 131, 10, 0.938);
}
.close {
  position: absolute;
  right: 20px;
  top: 20px;
}
@media screen and (max-width: 1100px) {
  .services {
    width: 50%;
  }
  .sideIcon {
    width: 50%;
  }
  .sideIcon img {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .services {
    width: 100%;
  }
  .serviceExpand {
    top: 0px;
  }
  .advantages{
    width:fit-content;
    display: block;
    margin: auto;
  }
}
.serviceImg {
  cursor: pointer;
  margin: auto;
  border-radius: 99999px;
  width: 250px;
}
.serviceImg img {
  border-radius: 99999px;
  width: 250px;
  height: 250px;
  object-fit: cover;
}
.serviceTitle {
  cursor: pointer;
}
.animateSrv img {
  animation: serviceAnimate 500ms linear;
}
@keyframes serviceAnimate {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.serviceImg:hover img {
  box-shadow: 0 0 18px rgba(107, 182, 22, 0.938);
}
.serviceTitle {
  max-width: 100%;
  text-align: center;
  font-size: 22px;
  flex-wrap: wrap;
}
.footerSection {
  margin-bottom: 80px;
}
#footer {
  margin: auto;
  width: 100%;
  max-width: 1200px;
  text-align: center;
}
.contact {
  display: flex;
  width: 100%;
}
.contact > * {
  width: 50%;
}
#footer a {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  #header {
    padding: 5px;
  }
}
@media screen and (max-width: 800px) {
  .sideIcon {
    display: none;
  }
  .about {
    font-size: 1.2em;
    padding: 0 15px;
  }
  .contact {
    display: block;
  }
  .contact > * {
    width: 100%;
    padding: 20px;
  }
  .aboutText {
    width: 100%;
  }
}
